const scratchProjectsData = [
    {
        icon: "./arrayOfImg/itArtashat.png",
        title: "ITARTASHAT",
        src: "https://www.itartashat.am/",
    },
    {
        icon: "./arrayOfImg/sandallwood.png",
        title: "SANDALWOOD",
        src: "https://www.sandalwoodstyle.com/",
    },
    {
        icon: "./arrayOfImg/tmak.png",
        title: "TMAK",
        src: "https://tmak.am/",
    },
    {
        icon: "./arrayOfImg/ba-armenia.png",
        title: "BA-ARMENIA",
        src: "https://ba-armenia.am/hy/",
    },

    {
        icon: "./arrayOfImg/vld-specconst.png",
        title: "VLD-SPECCONST",
        src: "https://www.vld-specconst.com",
    },
    {
        icon: "./arrayOfImg/it-cafe.png",
        title: "IT-CAFE",
        src: "https://it-cafe-prodacion.vercel.app/",
    },
    {
        icon: "./arrayOfImg/imgmarinesite.jpg",
        title: "CV webSite",
        src: "https://marina-mkhitaryan-marines-projects-74d13cf6.vercel.app/",
    },
    {
        icon: "./arrayOfImg/HVG.png",
        title: "HVG",
        src: "https://www.hvg.am/",
    },
    {
        icon: "./arrayOfImg/Gevorgyan.png",
        title: "GEVORGYAN-NERSISYAN",
        src: "https://www.gevorgyan-nersisyan.am/",
    },
    {
        icon: "./arrayOfImg/apricotartashat.jpg",
        title: "Tsiranashat Artashat",
        src: "https://tsiranashatartashatweb-git-main-davs-projects-9f835d38.vercel.app/",
    },
    {
        icon: "./arrayOfImg/Constraction.png",
        title: "CONSTRACTION GROUP",
        src: "https://www.constructiongroup.am/",
    },
    {
        icon: "./arrayOfImg/bellagroup.png",
        title: "BELLA Group",
        src: "https://bellagroup.gr/",
    },
   
];

export default scratchProjectsData;

import React, { useRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HomePresentation from "./components/HomeBlock/HomePresentation/HomePresentation";
import HomeCourses from "./components/HomeBlock/HomeCourses/HomeCourses";
import HomeCenter from "./components/HomeBlock/HomeCenter/HomeCenter";
import HomeTeatcher from "./components/HomeBlock/HomeTeatcher/HomeTeatcher";
import HomeAbout from "./components/HomeBlock/HomeAbout/HomeAbout";
import HomeContact from "./components/HomeBlock/HomeContact/HomeContact";
import Projects from "./pages/projects";
import WebProjects from "./pages/websites";
import Sineup from "./pages/sine_up";
import Order from "./pages/orders";
import Products from "./pages/products";

function App() {
    const coursesRef = useRef(null);
    const centerRef = useRef(null);
    const teatcherRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);
    const navigate = useNavigate();
//
    // const scrollCoursesComponent = () => {
    //     coursesRef.current.scrollIntoView({ behavior: "smooth" });
    // };

    const scrollCenterComponent = () => {
        centerRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollTeatcherComponent = () => {
        setTimeout(() => {
            navigate("./");
        }, 500);
        teatcherRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollAboutComponent = () => {
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollContactComponent = () => {
        navigate("/sine_up");
    };

    const renderHomPage = () => {
        return (
            <div className="main-app">
                <HomePresentation onclick={scrollContactComponent} />
                {/* <HomeCourses ref={coursesRef} /> */}
                <HomeCenter ref={centerRef} />
                <HomeTeatcher ref={teatcherRef} />
                <HomeAbout ref={aboutRef} />
                <HomeContact ref={contactRef} />
            </div>
        );
    };

    return (
        <div className="App">
            <Header
                // scrollCoursesComponent={scrollCoursesComponent}
                scrollCenterComponent={scrollCenterComponent}
                scrollTeatcherComponent={scrollTeatcherComponent}
                scrollAboutComponent={scrollAboutComponent}
                scrollContactComponent={scrollContactComponent}
            />
            <Routes>
                <Route path="/" element={renderHomPage()} />
                <Route path="/courses" element={<HomeCourses />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/odersite" element={<Order />} />
                <Route path="/sine_up" element={<Sineup />} />
                <Route path="/webProjects" element={<WebProjects />} />
                <Route path="/products" element={<Products />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
